/* ---------------------------------------------------------
HOME COLORS
--------------------------------------------------------- */
$('.open-color-list').click(function () {
    var colorListWrapper = $( this ).parent().parent().find( '.home-color-list-wrapper' );

    colorListWrapper.toggleClass( 'minified' ); // Target <article>

    if (colorListWrapper.hasClass('minified')) {
        $(this).find('.minified-color-list-text').show();
        $(this).find('.unminified-color-list-text').hide();
        colorListWrapper.find('.unminified').hide();
    } else {
        $(this).find('.minified-color-list-text').hide();
        $(this).find('.unminified-color-list-text').show();
        colorListWrapper.find( '.unminified' ).show();

        $(".open-color-list").click(function() {
            $('html, body').animate({
                scrollTop: $(".home-color-list-wrapper").offset().top
            }, 300);
        });
    }
});

/* ---------------------------------------------------------
CUSTOM SELECT MENU
--------------------------------------------------------- */
function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;

    if (select === undefined) {
        select = 'custom-select';
    }

    x = document.getElementsByClassName(select);

    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }

    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");

        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }

            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;

            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }

            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;

                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;

                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");

                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }

                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });

            b.appendChild(c);
        }

        x[i].appendChild(b);

        a.addEventListener("click", function (e) {
            e.stopPropagation();

            // Raz de tous les autres select
            closeAllSelect(this);

            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");

            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {
    if (select_str === undefined) {
        select_str = '.custom-select';
    }

    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();

        // Raz de tous les autres select
        closeAllSelect(this);

        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");

        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });

    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;

        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;

        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;

                // Trigger pour lancer l'evènement
                $(s).trigger('change');

                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");

                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }

                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");

                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];

    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");

    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }

    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);

$(document).ready(function () {
    initCustomSelect();

    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;

        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });

    const filterWrapper = $('#rayon_sticky_block .left_filter_wrapper .filter_wrapper');

    if (filterWrapper.length > 4) {
        filterWrapper.addClass('addition_filter');
        filterWrapper.find('.filter_name').addClass('addition_filter');
        $('#filter_trier .filter_name').addClass('addition_filter');
    }
});

/* ---------------------------------------------------------
CHANGELOCALE
--------------------------------------------------------- */
function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: "post",
        data: {lang: locale, url: uri},

        success: function(data) {
            if (data != "") {
                var force = "";

                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;

            }
        },
    });
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
 function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent().addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;

    //Dans le cas ou une recherche est lancée depuis une page produit
    //On s'assure de cibler les informations de l'achat express du produit de la recherche
    //Afin d'éviter un bug sur l'achat express, dans le cas ou le produit de la recherche est le même que celui de la page produit
    //[0] est utilisé pour utiliser l'élement du DOM et non l'objet jQuery
    if ($('.dynasearchwrapper').length)  {
        var form = $('.dynasearchwrapper #prod_info' + elem)[0];
    } else {
        var form = $('#prod_info' + elem)[0];
    }

    var blocWrapperColorSize = $(".rollover_left",$(form));
    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    var txt_error = '';

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        blocWrapperColorSize.hide();
        $('#bloc_add_alert'+elem).show();
    } else {
        // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionné la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            waitingAddToBasket( event, form, '', regroup_ref_id );

            if ($(window).height() > 899 && $("#cart_top #cart_list .product-link").length > 3) {
                setTimeout(function() {
                    $("#cart_top #cart_list").overlayScrollbars({});
                }, 1001);
            } else if ($("#cart_top #cart_list .product-link").length > 2) {
                setTimeout(function() {
                    $("#cart_top #cart_list").overlayScrollbars({});
                }, 1001);
            }

            setTimeout(function() {
                btn.parent().removeClass('loading');
            }, 2000);

        } else {
            if (color_id === '' && size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size_and_a_color')+'</div>';
            } else if (color_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_color')+'</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size')+'</div>';
            }
            // Loader
            setTimeout(function() {
                btn.parent().removeClass('loading');
            }, 600);
            $(txt_error).insertBefore($("#ligne_pointure"+elem));
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.elements.namedItem('produit_id').value;
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    //Vérif pour éviter erreur JS
    var productRef = (form.elements.namedItem('produit_ref') !== null) ? form.elements.namedItem('produit_ref').value : '';
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    $(".productErrorFieldset").remove();

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        // Depending on the color we see if the product is available, do this only in product page
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);

        // We check if the product is already in the wishlist
        if (itm_rr_id) {
            productId = productId + itm_rr_id ;
        }
        checkProductInWishlist(productId, productRef, true);
    }
}

function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();

    var plnb = form.elements.namedItem('produit_lot_nb').value;

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hidden');

    var data = {
        idprod: form.elements.namedItem('produit_id').value,
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from === 'basket') {
        data.suff = form.elements.namedItem('produit_id').value;
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            var blockAlert = $("#bloc_add_alert" + elem,$(form));
            var blocWrapperColorSize = $(".rollover_left",$(form));
            var blockColor = $("#ligne_couleur" + elem,$(form));
            var blockSize = $("#ligne_pointure" + elem,$(form));
            $("#bloc_size_not_available" + elem).hide();
            if (res.stock_tampon == 0) {

                $(".eclat_product_nouveaute_fiche_produit").show();

                if (!is_achat_express) {
                    $("#ligne_couleur").addClass("epuise");
                    $(".productDescrWrapper .date_livraison").addClass("epuise");
                    $("#bloc_add_basket").addClass("disabled");

                    $('#ligne_qte').append($('.restock_date'));
                    $('.taunt_text').css('display', 'none');
                } else {
                    $('.bloc_add_color', $(form)).hide();
                }

                if (res.enableAlertStock) {
                    blockAlert.show();
                    blockAlert.addClass("actif");
                    $("#enableAlertStock").show();
                    if (is_achat_express) {
                        blockAlert.find(".bloc_add_alert_form").show();
                    }
                } else {
                    blockAlert.hide();
                    blockAlert.removeClass("actif");
                    $("#enableAlertStock").hide();
                    $("#bloc_size_not_available" + elem).show();
                }

                // Hide bloc colors and size
                blocWrapperColorSize.hide()

                if ($('#bloc_add_basket',$(form)) != undefined) {
                    $('#bloc_add_basket',$(form)).hide();
                }
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                $(".eclat_product_nouveaute_fiche_produit").hide();
                if (!is_achat_express) {
                    $("#bloc_add_basket").removeClass("disabled");
                    $("#ligne_couleur").removeClass("epuise");
                    $(".productDescrWrapper .date_livraison").removeClass("epuise");

                    $('#bloc_add_basket').removeClass('inactif');
                    $('#btn_add_cart').removeAttr('disabled');
                } else {
                    $('.bloc_add_color', $(form)).show();
                }
                blockAlert.hide();
                blockAlert.removeClass("actif");

                // Show bloc colors and size
                blocWrapperColorSize.css('display', 'flex');

                if ($('#bloc_add_basket').length == 1) {
                    $('#bloc_add_basket').show();
                }
            };
            // Datalayer v2
            if (res.dataLayer_object != undefined) {
                pushIntoDatalayer(res.dataLayer_object);
            }
        }
    });
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {
            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        // DataLayer addToCart
        if (array_panier[5] != undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if ($(window).height() > 899 && count_bask > 3) {
            $("#cart_top #cart_list").overlayScrollbars({});
        } else if (count_bask > 2) {
            $("#cart_top #cart_list").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }
    
    // moving ECLAT element to top_info (hover panier) - WP-27657
    $('#cart_top .product-link').each(function() {
        let item = $(this).find('.eclat_rond');
        let newParent = $(this).find('.top_info');
        item.appendTo(newParent);
    });

    return false;
}

// Alters item quantity in cart
function changeQte(type) {
    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {
        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                //update top cart
                var response = ajax_file(create_link('ajax_show_basket'));
                document.getElementById('cart_top').innerHTML = response;

                updateCartElements();
                $('#alma-widget').insertBefore('.bloc_question');
            }
        });
    }


}

// FUNCTION PUSH INTO DATALAYER
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function createDatalayer(prod_id, url) {
    if (productlist_gondole) {
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id) {
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
                create_link('ajax_update_basket') +
                "?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

function updateCartElements () {
    let cart = Translator.translate('mon_panier');
    var total = $('#cart_top .cart').attr('data-cart'),
    text,cartText;
    var cartTotal = $('#cart_top .cart').attr('data-cart');

    if (total <= 1) {
        text = Translator.translate('one_product');
        cartText = Translator.translate('total_global_nb_product');
    } else {
        text = Translator.translate('2_product');
        cartText = Translator.translate('total_global_nb_products');
    }

    let nbWishlist = $(".cart_main_table.wishlist .cart_product_line").length;
    let nbWishlistTxt = nbWishlist > 1 ? Translator.translate('2_product') : Translator.translate('one_product');

    $('.cart_main_title.basket').html(cart + ' <span class="nb_products">(' + total + ' ' + text + ')</span>');
    $(".cart_main_title.wishlist .nb_products").html(' <span class="nb_products">(' + nbWishlist + ' ' + nbWishlistTxt + ')</span>');
    $('#cart_total .top_nb_products label').html(cartTotal + ' ' + cartText);

    //cart code promo nb product line
    if($('#cart_total .used_cp').length) {
        $('.top_nb_products').addClass('visible');
    } else {
        $('.top_nb_products').removeClass('visible');
    }
    var amount = $('#montant_txt').html();
    amount = amount.replaceAll(/[^0-9]/g, '');

    initAlmaWidgetBasket(amount);

    addToWishlistUpdateHeader();

    // moving ECLAT element to top_info (hover panier) - WP-27657
    $('#cart_top .product-link').each(function() {
        let item = $(this).find('.eclat_rond');
        let newParent = $(this).find('.top_info');
        item.appendTo(newParent);
    });
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function() {
        topCartDesappear();
    }, 5000);
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartAppear(el) {
    var show_top_cart_parent = $('.cart_box', el);
    var rollover_parent = show_top_cart_parent.parent().attr( 'id' );

    if (rollover_parent === 'cart_top') {
        var count_bask = $( '#cart_top .cart' ).attr( 'data-cart' );

        if ($(window).height() > 899 && count_bask > 3) {
            $(".cart_product_listing", el).overlayScrollbars({});
        } else if (count_bask > 2) {
            $(".cart_product_listing", el).overlayScrollbars({});
        }
    } else if (rollover_parent === 'wishlist_top') {
        var count_bask = $('#wishlist_top .cart').attr('data-cart');

        if ($(window).height() > 899 && count_bask > 3) {
            $(".cart_product_listing", el).overlayScrollbars({});
        } else if (count_bask > 2) {
            $(".cart_product_listing", el).overlayScrollbars({});
        }
    }

    show_top_cart_parent.addClass("show");
    $('#shad_menu').addClass("actif");
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartDesappear() {
    var show_top_cart_parent = $('.cart_box');

    show_top_cart_parent.removeClass("show");
    $('#shad_menu').removeClass("actif");
}

var elmt;
var loader;
var prev_display;

// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + create_link('ajax_deconnect'));

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + create_link('ajax_choose_preco'),
        success: function(response) {

            if (response.status == "success") {

                if ($("input[name=preco_choice]").length == 0) {

                    closeMultiShad("lightbox_preco");
                }

                var index_conveyor = typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {

                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {

                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr( "checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {

                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked","checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    closeMultiShad(elem);
}

function openMultiShad(id) {
    $("#" + id).addClass('actif').show();
    $("#shad").addClass('actif').show();

    $("#shad").on("click", function () {
        closeMultiShad(id);
    } );
}

function closeMultiShad(id) {
    $("#shad").off("click");
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        url: path_relative_root + create_link('ajax_removeBasketProd'),
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
        },
        success: function(res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top").html(res.datas.html);
            $('#cart_top #show_top_cart').addClass('show');

            var count_bask = $( '#cart_top .cart' ).attr( 'data-cart' );

            if ($(window).height() > 899 && count_bask > 3) {
                $("#cart_top #cart_list").overlayScrollbars({});
            } else if (count_bask > 2) {
                $("#cart_top #cart_list").overlayScrollbars({});
            }

            // moving ECLAT element to top_info (hover panier) - WP-27657
            $('#cart_top .product-link').each(function() {
                let item = $(this).find('.eclat_rond');
                let newParent = $(this).find('.top_info');
                item.appendTo(newParent);
            });
        },
    });
}

// Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function closeAlerteStock( elem ) {
    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();
    // $("#prod_info_" + elem + " .bloc_add_color").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    // $('.form_submit.bloc_add_color').show();

    //Reset input selection
    // $('#size_list_' + elem + ' input').prop('checked', false);
}

/**
 * Update colors / size picto depends on stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
 function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var currentCorlor = $(this).val();
        var size_box, is_disabled;

        // Disable all colors without stock
        // For each colisage
        window.json_colisages.forEach(function(colors) {
            // For each colors
            Object.keys(colors).forEach(function(color_id) {
                var no_sizes = 0;
                // For each size
                Object.keys(colors[color_id]).forEach(function(size_id) {
                    if (color_id == currentCorlor) {
                        if (colors[color_id].hasOwnProperty(size_id)) {
                            size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                                '[value="' + size_id + '"]'
                            );
                            is_disabled = !colors[color_id][size_id];
                            if ( is_disabled )  {
                                size_box.addClass('disabled');
                            }
                            else {
                                size_box.removeClass('disabled');
                            }
                        }
                    }

                    // If stock for this size / color doesn't exist
                    if (colors[color_id][size_id] == false) {
                        no_sizes++;
                    }
                });
                // If product don't have any size / colors stock
                if (Object.keys(colors[color_id]).length == no_sizes) {
                    $('#color_'+color_id+'+label').addClass('no_sizes');
                } else {
                    $('#color_'+color_id+'+label').removeClass('no_sizes');
                }
            });
        });
    }
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
                create_link('ajax_liste_quantity') +
                "?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

var tempo_reset = false;

function initAllFilters() {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

    // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
    if ( $( '.wrap_rolloverproduit' ).length ) {
        fixAchatExEnfant();
    }
}

var bloc_prod_xhr;

// Used to display products
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat);
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    //Added for infinite scroll to top
    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");
    }

    if (page === 'all') {

    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en-en']").attr("href", JSON.parse(res[8]).en);
                $("link[rel^='alternate'][hreflang^='de-de']").attr("href", JSON.parse(res[8]).de);
                $("link[rel^='alternate'][hreflang^='it-it']").attr("href", JSON.parse(res[8]).it);
                $("link[rel^='alternate'][hreflang^='nl-nl']").attr("href", JSON.parse(res[8]).nl);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                } else {
                    old_elm.innerHTML = Translator.translate('no_result_txt');
                }
            },
            complete: function () {
                //force trigger on hidden input to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $( '#page' ).trigger( 'change' );

                $( '.selected_options' ).each( function () {
                    var activeFilters = $( this ).find( 'input[type*="checkbox"]:checked' ).length;
                    var corresondingCounter = $( this ).prev( '.filter_name' ).find('.counter');

                    if (activeFilters) {
                        corresondingCounter.html('('+ activeFilters +')');
                    } else {
                        corresondingCounter.html('');
                    }
                } );

                // If we reload the page, we center on the new display
                if (!fromScrollBottom && !fromScrollTop) {
                    // Reset initialPage if from filter
                    $("#initial_page").trigger("change");
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                    seeAllBtnClick("add");
                }

                if (fromScrollBottom) {
                    $("#initial_page").val(page +1);
                }

                // UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)

                var productLoaded = $('#list_item .item:not(".push"):not(".item_push")').length;
                var total_element = document.getElementById('totalElems').value;

                $('.txt_bandeau span').html(total_element + ' ' + Translator.translate('2_product'));

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }

                setTimeout(function () {
                    $('.pagerNav').addClass('appear');
                }, 500);

                // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
                if ($('.wrap_rolloverproduit').length) {
                    fixAchatExEnfant();
                }
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // If we are on the search page
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

function paginate(page) {
    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat), // 242
        nb_prod = form.getValue('nb_prod', parseFloat), // 30
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

        page = page || 1;

        $.ajax({
            url: path_relative_root + create_link('ajax_reload_pagination'),
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('#list_item .item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {

                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        });
    }
}

// Called upon color change in product page and catalog page
function onItemColorChange(form, is_regroup, itm_rr_id, page_type) {
    var control, prod_id;
    type_page = (page_type !== undefined && page_type !== '' ) ? page_type : '',
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur' , type_page, itm_rr_id);
        }
    }
}

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");
        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();
        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {
            this.alt = field.alt;
            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var productRef = this.getValue('produit_ref');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    var search = form.getValue('isSearch');
    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_ref : productRef,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        search : (search ? true : false)
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link("ajax_get_product_regroup_ref"),
        data : data,
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;
                if (data.type_page == 'product') {
                    // Main HTML
                    var $html_main = $(stripCombo(datas.html_main));
                    //Save ariane to prevent delete
                    var saveAriane = $('.page_produit #breadcrumbs');

                    // HTML Photo et informations
                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    // HTML Associated products
                    var product_associations = $('#product_associations_manual', $html_main);
                    // HTML Eresa
                    var eresa = $('#e-resaBox', $html_main);

                    // HTML CMS module
                    var product_cms_bloc = $('#product_cms_bloc', $html_main);

                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                            $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $( 'input#color_' + new_id + '_' + data.couleurProd + itm_rr_id ).prop( 'checked', true );

                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }
                    } else {
                        // Update HTML Photo et informations
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        //Replace ariane by the previous one
                        $('#breadcrumbs').replaceWith(saveAriane);
                        // Update HTML Associated products
                        $('#product_associations_manual', '#site_global_wrap').html(product_associations.html());

                        // Update HTML ERESA
                        $('#e-resaBox', '#site_global_wrap').html(eresa.html());

                        // Update HTML CMS module
                        $('#product_cms_bloc', '#site_global_wrap').html(product_cms_bloc.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // Fiche Produit Swiper
                        setTimeout(function() {
                            // do no slider if there's only one slide
                            if ($('#visual_scroller .swiper-wrapper .swiper-slide').length <= 1) {
                                $('.product_left_image').addClass('appear');
                                return;
                            }

                            var productThumbs = new Swiper('#visual_scroller', {
                                slidesPerView: 4,
                                spaceBetween: 11,
                                direction: 'vertical',
                                watchSlidesVisibility: true,
                                watchSlidesProgress: true,
                            });

                            var main_productVisu = new Swiper('#jqzoom', {
                                direction: 'vertical',
                                slidesPerView: 1,
                                watchSlidesVisibility: true,
                                watchSlidesProgress: true,
                                thumbs: {
                                    swiper: productThumbs
                                },
                                navigation: {
                                    nextEl: '#visual_scroller_wrapper .thumbNext',
                                    prevEl: '#visual_scroller_wrapper .thumbPrev',
                                },
                                preloadImages: false,
                                lazy: true,
                                loadPrevNext: true,
                                loadOnTransitionStart: true,
                                mousewheel: true,
                                loop: true,
                            });


                            $('.product_left_image').addClass('appear');
                        }, 250);

                        // if .visual_scroller .swiper-wrapper only contains one slide, we hide the arrows
                        if ($('#visual_scroller .swiper-wrapper .swiper-slide').length <= 1) {
                            $('#visual_scroller_wrapper .thumbNext').hide();
                            $('#visual_scroller_wrapper .thumbPrev').hide();
                        }

                        // ZoomBox Thumbs Swiper
                        if ($("#thumbs_scroller").length) {
                            productZoomThumbs = new Swiper('#thumbs_scroller', {
                                observer: true,
                                observeParents: true,
                                observeSlideChildren : true,
                                slidesPerView: 4,
                                spaceBetween: 8,
                                direction: 'vertical',
                                navigation:
                                {
                                    nextEl: '.thumb_wrapper .zoomNext',
                                    prevEl: '.thumb_wrapper .zoomPrev',
                                },
                            });
                        }

                        if ($("#zoom_box_wrapper").length) {
                            var activeImage = $( '#jqzoom .swiper-slide-active' ).index();

                            // ZoomBox Product Swiper
                            var zoom_productVisu = new Swiper( '#zoom_box_wrapper', {
                                slidesPerView: 1,
                                initialSlide: activeImage,
                                loadOnTransitionStart: true,
                                thumbs: {
                                    swiper: productZoomThumbs
                                },
                                navigation:
                                {
                                    nextEl: '.zoom_main_wrapper .zoomNext',
                                    prevEl: '.zoom_main_wrapper .zoomPrev',
                                },
                                lazy: true,
                            } );

                            // if #zoom_box_wrapper .swiper-wrapper only contains one slide, we hide the arrows
                            if ($('#zoom_box_wrapper .swiper-wrapper .swiper-slide').length <= 1) {
                                $('.zoom_main_wrapper .zoomNext').hide();
                                $('.zoom_main_wrapper .zoomPrev').hide();
                            } else {
                                $('.zoom_main_wrapper .zoomNext').show();
                                $('.zoom_main_wrapper .zoomPrev').show();
                            }
                        }

                        if ($("#product_associations_manual_slider .swiper-slide").length) {
                            swiperBuilder('product_associations_manual_slider');
                        }

                        if ($("#product_associations_rayon_slider .swiper-slide").length) {
                            swiperBuilder('product_associations_rayon_slider');
                        }

                        if ($('body').hasClass('product_page')) {
                            $( '#zoomBox .img_large' ).on( 'click', function () {
                                if (!$(this).hasClass('zoomTriggered')) {
                                    $( this ).addClass( 'zoomTriggered' );
                                    zoomMainimg($(this));
                                } else {
                                    $( this ).removeClass( 'zoomTriggered' );
                                    $( this ).css( { 'transform-origin': 'initial' } );
                                    $( this ).css( { 'transform': 'scale(' + 1 + ')' } );
                                }
                            });

                            $('#zoomBox .img_large.zoomTriggered').each(function () {
                                $(this).on('mouseenter', function () {
                                    zoomMainimg($(this));
                                });
                            } );

                            $( '.zoom_main_wrapper' ).on( 'click', function ( e ) {
                                if ( e.target == this ) {
                                    closeMultiShad('zoomBox');
                                }
                            });
                        }

                        // Getting selected color
                        var selected_color = $( '#productInfos input[name*="itm_color"]:checked' ).data('nom');

                        // Setting selected color
                        $( '#ligne_couleur .prod_listes > label' ).append('<span> : ' + selected_color + '</span>');
                    }
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {
            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                checkSubmitState();
                eventButton();

                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();
            }, 500);

            heads.each(function() {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            if (!data.is_achat_express_v2) {
                getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist, data.produit_ref);
                checkProductInWishlist(data.produit_id_new, data.produit_ref);
                initSwiperReassuranceProduct();
            }

            // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
            if ($('.wrap_rolloverproduit').length) {
                fixAchatExEnfant();
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop()+2);
        }
    });
}

// Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 function lightboxAlertStock() {
    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }

        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));

        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');
    var isBtnVisible = $('#btn_add_alert').is(':visible');

    if ( $('body').hasClass('product_page') ) {
         if (isBtnVisible) {
            $('#btn_add_alert').css('display', 'none');
            $('.bloc_add_alert_confirmation').css('display', 'none');
            $('.bloc_add_alert_erreur').css('display', 'none');

        } else {
            $('#btn_add_alert').css('display', 'block');
        }
    }

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if ( isFormVisible ) {
            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {
            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('.bloc_add_alert_form').slideDown('fast');
    }
}

$(document).on("click", ".thumblnk", function(evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.next(".thumbcnt");

    evt.preventDefault();

    thumbnav
        .find(".thumblnk")
        .not(".thumblnk[data-pannel=" + id_pannel + "]")
        .removeClass("current");
    thumbcnt
        .find(".thumbslide")
        .not(".thumbslide[data-pannel=" + id_pannel + "]")
        .removeClass("current");

    thumbnav
        .find(".thumblnk[data-pannel=" + id_pannel + "]")
        .addClass("current");
    thumbcnt
        .find(".thumbslide[data-pannel=" + id_pannel + "]")
        .addClass("current");
});

$(function () {
    if($('.page_produit').length) {
        initSwiperReassuranceProduct();
    }
})

// Listener on product feat title in product page to display feat's description
$(document).on("click", ".productFeatTitle", function () {
    var productFeatDescription = $('.productFeatDescription');
    var that = $(this);
    if (that.hasClass('current')) {
        that.next(productFeatDescription).slideUp("fast", function () {
            that.removeClass('current');
        });
    } else {
        that.next(productFeatDescription).slideDown("fast", function () {
            that.addClass('current');
        });
    }
});

function initSwiperReassuranceProduct() {
    if ($("#swiper_reassurance_product").length) {
        new Swiper('#swiper_reassurance_product', {
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            paginationClickable: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }
}

function zoomImg(slide) {
    $('#zoom_box_wrapper').css('opacity', 0);

    // ZoomBox Product Swiper
    new Swiper( '#zoom_box_wrapper', {
        initialSlide: slide,
        loadOnTransitionStart: true,
        thumbs: {
            swiper: productZoomThumbs
        },
        navigation:
        {
            nextEl: '.zoomNext',
            prevEl: '.zoomPrev',
        },
        slidesPerView: 1,
        lazy: true,
    } );

    // if #zoom_box_wrapper .swiper-wrapper only contains one slide, we hide the arrows
    if ($('#zoom_box_wrapper .swiper-wrapper .swiper-slide').length <= 1) {
        $('.zoom_main_wrapper .zoomNext').hide();
        $('.zoom_main_wrapper .zoomPrev').hide();
    } else {
        $('.zoom_main_wrapper .zoomNext').show();
        $('.zoom_main_wrapper .zoomPrev').show();
    }

    window.setTimeout(function () {
        $('#zoom_box_wrapper').animate({opacity: 1}, 650);
    }, 350);

    openMultiShad('zoomBox');
}


function updateSrc(path_web, dest) {
    var tmp_string_large = document.getElementById("img_large").src;

    tmp_string = tmp_string_large.replace(/medium/, "large");

    document
        .getElementById("img_large")
        .setAttribute(
            "onclick",
            "zoomImg('" + tmp_string + "','" + path_web + "')"
        );

    document.getElementById("loupe").href = "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";

    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

    if (dest == "vue") {
        var i = 0;
        var trouve = false;

        while (i < tab_js.length && !trouve) {
            trouve =
                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
            i++;
        }

        cpt_img = i - 1;
    }
}

function ajax_achat_express(reference, wishlist = false, idprod, code_couleur) {
    var arg = '';

    if (wishlist == true) {
        arg += '&wishlist';
    }

    $.ajax({
        type: 'get',
        url: path_relative_root + create_link('ajax_achat_express') + "?ref="+reference+'&couleur='+code_couleur+arg,
        success: function( response ) {
            if (!$('#lightbox_achat_express').length) {
                $('body').append('<div id="lightbox_achat_express" class="lightbox"></div>');
            }
            $('#lightbox_achat_express').html( '<div id="shad_sizeguide" class="shad"></div><div class="express_response"><button class="bgSprite bfr wool close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')"></button>'+ response +'</div>' ).appendTo( 'body' );
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);
        }
    });
}

// PROD ASSOCS DATA LAYERS
function dataLayer_associated_product () {
    if (window.$('.otherProduct')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;

        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item) {
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }

            var prod = productObj;

            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

/* ---------------------------------------------------------
SEEXPRODUCT
--------------------------------------------------------- */
function seeXProduct(nb_product, page, infinite) {
    // Enable scroll infinis
    if (infinite == true) {
        $("#see_all").val("1");

        generateNewBlocProd((page -1));

        $("#initial_page").val(page);
    } else { // disable scroll infinis
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);

        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();

    let datas = [];
    let selectedTopProducts = {};

    $(".remove_prod.wishlist_remove").each(function() {

        datas.push({
            productId: $(this).attr("data-produit-id"),
            colorId: $(this).attr("data-color-id"),
            sizeId: $(this).attr("data-size-id"),
        });
    });

    selectedTopProducts.selectedProducts = JSON.stringify(datas);
    moveProductFromWishlistToCart(false, selectedTopProducts);
});

// Affichelostpassword
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

function fbs_click(site, params) {
    if (params == '') {
        params = location.href;
    }

    switch (site) {
        case 'fb':
            window.open('http://www.facebook.com/share.php?u='+encodeURIComponent(params)+'&t=', '_blank', '' , 'width=626,height=436');
            break;
        case 'tw':
            window.open('https://twitter.com/share?url='+params, '_blank', '' , 'width=626,height=436');
            _gaq.push(['_trackSocial', 'twitter', 'Tweet', params]);
            break;
        case 'insta':
            window.open('https://www.instagram.com/sabonfrance/', '_blank', '' , 'width=626,height=436');
            break;
        case 'pin':
            window.open('http://pinterest.com/pin/create/button/?'+params, '_blank', '' , 'width=626,height=436');
            var url = params.split('&');
            url = url[0].split('=');
            url = decodeURIComponent( url[1] );
            break;
    }
    return false;
}

$(document).on("mouseenter","#zoomBox .img_large.zoomTriggered", function () {
    zoomMainimg($(this));
});

function getWishlistProducts () {
    let array_elements = document.querySelectorAll('div[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));
        $.ajax({
            type: 'get',
            url: path_relative_root + "get_user_all_wishlist.php",
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != 'undefined' && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                            $('#wishlistButton_' + array_product_id[product_wishlist].fk_produit_id).removeClass('addToWishlistButtonPreview');
                            $('#wishlistButton_' + array_product_id[product_wishlist].fk_produit_id).addClass('existToWishlistButtonPreview');
                        }
                    }
                }
            }
        });
    }
}

window.onload = function(){
    getWishlistProducts();
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + create_link('order_delivery_ajax'),
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

function closeSizeNotAvailable(elem) {
    // Hide bloc alert stock
    $('#bloc_size_not_available_' + elem).hide();

    $('#ligne_couleur_' + elem).parent().show();
    // Show bloc colors
    $('#ligne_couleur_' + elem).show();
    $('.wishlistBtn').show();
    $('.bloc_add_color').show();
}

function addToWishlistUpdateHeader(nbWishlist) {

    var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist_top").html(response_html);

    // HEADER SCROLLBARS
    if ($('#wishlist_list').length) {
        $('#wishlist_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }
}